import React, { useState } from 'react';
import PropTypes from 'prop-types';
import edit from '../../assets/edit.png'
import { useNavigate } from 'react-router-dom';
const SalesOrderPendencyRow = ({ data }) => {
   const navigate = useNavigate();
   
   const handleview=(reportId)=>{
       navigate(`/viewclientwisereport/${reportId}`)
   }

    return (
        <tr className={`operation_list_table_body `}>
            <td data-label="S.No.">{data.id}.</td>
            <td data-label="Order Date">{data.orderDate}</td>
            <td data-label="Due Days">{data.dueDays}</td>
            <td data-label="Item Name">{data.itemName}</td>
            <td data-label="Order QTY">{data.orderQty}</td>
            <td data-label="Pending QTY">{data.pendingQty}</td>
            <td>
                <div
                    className='sales_order_pendency_edit_button'
                >
                    <img onClick={()=>handleview(data.id)}   src={edit} alt='edit'/>
                </div>
            </td>
        </tr>
    );
};

SalesOrderPendencyRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        dueDays: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        orderQty: PropTypes.string.isRequired,
        pendingQty: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        remark: PropTypes.string.isRequired,
    }).isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    handleRemarkChange: PropTypes.func.isRequired,
};

export default function ClientWisePendencyReport() {
    const initialSalesOrderPendency = [
        { id: '1', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', },
        { id: '2', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100',},
        { id: '3', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', },
    ];
    const [pendency, setPendency] = useState(initialSalesOrderPendency);
   
   
     
    return (
        <>
            <div className='sales_order_pendency_section_header'>
                <h4>Client Wise Pendency Report</h4>
          
                    <div className='sales_order_pendency_date_section'>
                    <div className='sales_order_pendency_date'>
                    Start Date
                        <input type='date'/>
                    </div>
                    <div className='sales_order_pendency_date'>
                    End Date
                        <input type='date'/>
                    </div>
                    </div>
                
            </div>
            <section className='operation_section'>
                <div>
                    <table className='operation_list_table'>
                        <thead>
                            <tr className='operation_list_table_head'>
                                <th>S.No.</th>
                                <th>Order Date</th>
                                <th>Due Days</th>
                                <th>Item Name</th>
                                <th>Order QTY</th>
                                <th>Pending QTY</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendency.map((data) => (
                                <SalesOrderPendencyRow 
                                    data={data} 
                                    key={data.id} 
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </section>
           
        </>
    );
}
