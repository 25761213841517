import React from 'react'
import './dashboard.css'
import PieChartComponent from '../../component/helper/PieChartComponent'
export default function Dashboard() {
  return (
    <div>
    <section className='first-dashboard-section'>
    <section className='Order-status-section'>
        <div><h3>Order Status</h3></div>
        <div className='order-status-circles'>
            <div className='completed-status'>
                <span className='completed-status-circle'></span>
                Completed
            </div>
            <div className='processing-status'>
                <span className='processing-status-circle'></span>
                Processing
            </div>
            <div className='pending-status'>
                <span className='pending-status-circle'></span>
                Pending
            </div>
            <div className='reschedule-status'>
                <span className='reschedule-status-circle'></span>
                Re-Schedule
            </div>
        </div>
        <div className='order-status-big-circles'>
        <div className='completed-status-big-circle'>
                <span>75</span>
            </div>
        <div className='processing-status-big-circle'>
                <span>55</span>
            </div>
        <div className='pending-status-big-circle'>
                <span>46</span>
            </div>
        <div className='reschedule-status-big-circle'>
                <span>25</span>
            </div>

        </div>
    </section>
    <section className='total-clients'>
    <div className='total-clients-header'>
        <h3>Total Clients</h3>
        <div className='total-clients-header-viewall'>View all<i class='bx bx-arrow-back bx-rotate-180' ></i></div>
    </div>
    <div className='Clients-feedback'>
        <div className='total-clients-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-clients-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
        <div className='total-clients-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-clients-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
        <div className='total-clients-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-clients-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
    </div>
    <div className='total-clients-footer'>
        250+ Clients
    </div>

    </section>
    </section>
    <section className='second-dashboard-section'>
    <section className='Bar-graph-section'>
      <div className='Bar-graph-1'>
      <h3>Total Operation</h3>
        <PieChartComponent/>
      </div>
    </section>
    <section className='total-reports'>
     <h3>Total Reports</h3>
     <section className='progress-bar-section'>
     <div>
     <div className="total-reports-pending-bar-header">
        <span className='total-reports-pending-bar-title'>Pending Orders</span>
        <span className="total-reports-pending-bar-count">76</span>
      </div>
      <div className="total-reports-pending-bar">
        <div className="total-reports-pending-bar-fill" style={{ width: '76%' }}></div>
      </div>
     </div>
     <div>
     <div className="total-reports-production-bar-header">
        <span className='total-reports-production-bar-title'>Production  Report</span>
        <span className="total-reports-production-bar-count">66</span>
      </div>
      <div className="total-reports-production-bar">
        <div className="total-reports-production-bar-fill" style={{ width: '66%' }}></div>
      </div>
     </div>
     <div>
     <div className="total-reports-OperationPendency-bar-header">
        <span className='total-reports-OperationPendency-bar-title'>Consolidated Operation-wise Pendency</span>
        <span className="total-reports-OperationPendency-bar-count">36</span>
      </div>
      <div className="total-reports-OperationPendency-bar">
        <div className="total-reports-OperationPendency-bar-fill" style={{ width: '36%' }}></div>
      </div>
     </div>
     </section>
    </section>
    <section className='total-employees'>
    <div className='total-employees-header'>
        <h3>Total Clients</h3>
        <div className='total-employees-header-viewall'>View all<i class='bx bx-arrow-back bx-rotate-180' ></i></div>
    </div>
    <div className='employees-feedback'>
        <div className='total-employees-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-employees-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
        <div className='total-employees-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-employees-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
        <div className='total-employees-content'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAT6HDMbo1yTqh5VCzNvoFYHTaxdwSVnNUQ&s' alt='client'/>
            <div className='total-employees-text'>
                <div>Peaky Blinders</div>
                <small>Designer</small>
            </div>
        </div>
    </div>
    <div className='total-employees-footer'>
        250+ Clients
    </div>

    </section>

    </section>
     
    </div>
  )
}