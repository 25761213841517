import React, { useState } from 'react'
import pdf from '../../assets/pdf.png';
import add from '../../assets/add.png';
import './view_sales_order.css';
import { PhoneInput } from 'react-international-phone';

export default function SalesOrderDetails() {
    const [tabBar,setTabBar]= useState(false);
    const [phone,setphone] = useState();
    const handleClick = (value) => {
        setTabBar(value)
    }
  return (
    <>
        <section className='view_sales_order'>
            <header className='view_sales_order_header'>
                <h3>View Sales Order</h3>
               {tabBar === false ? <aside className='view_sales_order_sub_header'>
                   <div className='view_sales_order_btn'>                      
                        <button>Job Card</button>
                    </div>
                    <div className='view_sales_order_btn'>
                        <img src={add} alt=''></img>
                        <button>Move To Operation</button>
                    </div>
                    <div className='view_sales_order_btn'>
                        <img src={pdf} alt=''></img>
                        <button>View Bill PDF</button>
                    </div>
                </aside>:''}
            </header>
            <nav className='view_sales_order_tab_bar'>
            <div className={`view_sales_order_tab_bar_client ${tabBar === true ? 'tab_bar_active':''}`} onClick={()=>{handleClick(true)}}>
                Client Details
            </div>
            <div className={`view_sales_order_tab_bar_order ${tabBar === false ? 'tab_bar_active':''}`} onClick={()=>{handleClick(false)}}>
                Order Details
            </div>

            </nav>

            {tabBar === false ?
            <section className='view_sales_order_details'>
                <div className='new_sales_order_form'>
                    <form className='new_sales_order_form_main'> 
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_date'>Order Date</label>
                                    <input type='date' className='form-control' id='order_date' placeholder='Enter Order Date' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_id'>Order ID</label>
                                    <input type='text' className='form-control' id='order_id' value={`#${223242}`} placeholder='Enter Order Id' readOnly />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='item_name'>Item Name</label>
                                    <input type='text' className='form-control' id='item_name' placeholder='Enter Item Name' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='hsn_code'>HSN code</label>
                                    <input type='text' className='form-control' id='hsn_code' placeholder='41251' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='item_name'>Quantity</label>
                                    <input type='number' min='0' className='form-control number_input' id='item_name' placeholder='Enter Quantity' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_date'>Order Date</label>
                                    <input type='date' className='form-control' id='order_date' placeholder='Enter Order Date' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='rate'>Rate</label>
                                    <input type='text' className='form-control' id='rate' placeholder='₹14000' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='total_amount'>Total Amount</label>
                                    <input type='number' min='0' className='form-control number_input' id='total_amount' placeholder='Enter Total Amount' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='sales_person'>Sales Person</label>
                                    <input type='text' className='form-control' id='sales_person' placeholder='Enter Sales person' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <h6>GST Included 18%</h6>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    SGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    CGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    IGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </section>: <section className='view_sales_order_details'>

            <section className='client_details'>

<div>
    <form >
    <div className='client_details_form'>
        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="name">Client Name</label>
                    <input type="text" className="form-control" id="name" value='Mayur' aria-describedby="emailHelp" placeholder="Enter Name" readOnly />
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="address">Client Address</label>
                    <input type="text" className="form-control" id="address" value={"indore gate 5"} aria-describedby="emailHelp" placeholder="Enter Address" readOnly/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="company">Company Name</label>
                    <input type="text" className="form-control" id="company" value={"Bellway"} aria-describedby="emailHelp" placeholder="Enter Company Name" readOnly />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="city">City</label>
                    <select className='form-control'>
                        <option>Select City</option>
                        <option selected>Indore</option>
                        <option>City 2</option>
                        <option>City 3</option>
                    </select>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="pin">Pin Number</label>
                    <input type="text" className="form-control" id="pin" value={"897636"} aria-describedby="emailHelp" placeholder="Enter Pin Number"  readOnly/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="name">Company Name</label>
                    <PhoneInput
                        defaultCountry="in"
                        value={phone}
                        onChange={(phone) => setphone(phone)}
                        className="custom-phone-input"
                    
                        readOnly
                    />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="email">Email ID</label>
                    <input type='email' id='email' value={"Email@gmail.com"} className="form-control" aria-describedby="emailHelp" placeholder='Enter Email' readOnly/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="state">State</label>
                    <input type="text" className="form-control" value={"MP"} id="state"  placeholder="Enter State" readOnly  />
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label htmlFor="gst">GST Number</label>
                    <input type="text" className="form-control" value={"JSGqiwudbjg32"} id="gst"  placeholder="Enter GST" readOnly/>
                </div>
            </div>
    </div>
        </div>
    </form>
</div>
</section>
            </section>}
        </section> 
    </>
  )
}
