import React, { useState } from 'react'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './add_client.css';
import { useNavigate } from 'react-router-dom';
export default function AddClient() {
    const [phone, setphone] = useState();
     const navigate = useNavigate();
    const handleSubmit=()=>{
        navigate('/clientlist')
    }
    return (
        <>
            <section className='client_details'>
                <div className='client_details_header'>
                    <h3>Client Details</h3>
                    <div>
                        <button onClick={handleSubmit}className='client_details_save_btn'>Save</button>
                    </div>
                </div>

                <div>
                    <form >
                    <div className='client_details_form'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="name">Client Name</label>
                                    <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter Name" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="address">Client Address</label>
                                    <input type="text" className="form-control" id="address" aria-describedby="emailHelp" placeholder="Enter Address" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="company">Company Name</label>
                                    <input type="text" className="form-control" id="company" aria-describedby="emailHelp" placeholder="Enter Company Name" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="city">City</label>
                                    <select className='form-control'>
                                        <option>Select City</option>
                                        <option>City 1</option>
                                        <option>City 2</option>
                                        <option>City 3</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="pin">Pin Number</label>
                                    <input type="text" className="form-control" id="pin" aria-describedby="emailHelp" placeholder="Enter Pin Number" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="name">Company Name</label>
                                    <PhoneInput
                                        defaultCountry="in"
                                        value={phone}
                                        onChange={(phone) => setphone(phone)}
                                        className="custom-phone-input"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="email">Email ID</label>
                                    <input type='email' id='email' className="form-control" aria-describedby="emailHelp" placeholder='Enter Email'/>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="state">State</label>
                                    <input type="text" className="form-control" id="state"  placeholder="Enter State" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="gst">GST Number</label>
                                    <input type="text" className="form-control" id="gst"  placeholder="Enter GST" />
                                </div>
                            </div>
                    </div>
                        </div>
                    </form>
                </div>
            </section>

        </>
    )
}
