
import React from 'react';
import './Header.css'; // Import CSS file for styles
import profile from './profile.png';


export default function Header() {

  return (
    <div>
      <section>
        <header className="modern-header"> {/* Add a class for styling */}
          <div className="search-container">
            <i className='bx bx-search search-icon'></i>
            <input type="search" placeholder="Search" />
          </div>
          <div className='modern-header-icons'>
          <div className="notification-badge-container">
          <div className='bell-icon'><i className='bx bx-bell'></i></div>
          <span className="notification-badge">1</span>
</div>
           
            {/* Profile section */}
            <div className="profile-section">
              <img src={profile} alt="Profile" />
            </div>
            <div className='Logout-section'></div>
          </div>
        </header>
      </section>
    </div>
  );
}
