


import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import PrivateRoute from './component/protectedroute/PrivateRoute';
import { AuthProvider, useAuth } from './authentication/AuthContext';
import Layout from './component/Layout';
import AddClient from './pages/clientsmanagement/AddClient';
import ListClient from './pages/clientsmanagement/ListClient';
import SalesOrderList from './pages/ordermanagement/SalesOrderList';
import EditClient from './pages/clientsmanagement/EditClient';
import ClientView from './pages/clientsmanagement/ClientView';
import NewSalesOrder from './pages/ordermanagement/NewSalesOrder';
import EditSalesOrder from './pages/ordermanagement/EditSalesOrder';
import SalesOrderDetails from './pages/ordermanagement/SalesOrderDetails';
import OperationList from './pages/operationmanagement/OperationList';
import SalesOrderPendency from './pages/operationmanagement/SalesOrderPendency';
import ProductionFloorPlan from './pages/operationmanagement/ProductionFloorPlan';
import OperationMaster from './pages/operationmaster/OperationMaster';
import TotalPendingReport from './pages/reports/TotalPendingReport';
import ProductionReport from './pages/reports/ProductionReport';
import SalesOrderPendencyReport from './pages/reports/SalesOrderPendencyReport';
import SalesOrderReport from './pages/reports/SalesOrderReport';
import ClientWisePendencyReport from './pages/reports/ClientWisePendencyReport';
import ViewClientReport from './pages/reports/ViewClientReport';
function AppContent() {
  const { token } = useAuth();

  return (
    <Routes>
      <Route path="/" element={token ? <Navigate to="/dashboard" /> : <Login />} />
      {/* <Route path='/signup' element={<Signup />} /> */}
      
      <Route 
        path="/dashboard" 
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        } 
      />
      {/* Client Routes */}
      <Route 
        path="/addclient" 
        element={
          <PrivateRoute>
            <Layout>
              <AddClient />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/clientlist" 
        element={
          <PrivateRoute>
            <Layout>
              <ListClient />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/editclient/:clientId" 
        element={
          <PrivateRoute>
            <Layout>
              <EditClient />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/clientview/:clientId" 
        element={
          <PrivateRoute>
            <Layout>
              <ClientView />
            </Layout>
          </PrivateRoute>
        } 
      />
        {/* Client Routes */}
      {/* Order Management Routes */}
      <Route 
        path="/newsalesorder" 
        element={
          <PrivateRoute>
            <Layout>
              <NewSalesOrder />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/salesorder" 
        element={
          <PrivateRoute>
            <Layout>
              <SalesOrderList />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/editsalesorder/:orderId" 
        element={
          <PrivateRoute>
            <Layout>
              <EditSalesOrder />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/viewsalesorder/:orderId" 
        element={
          <PrivateRoute>
            <Layout>
              <SalesOrderDetails />
            </Layout>
          </PrivateRoute>
        } 
      />
        {/* Order Management Routes */}
      {/* Operation Management Routes */}
      <Route 
        path="/operationlist" 
        element={
          <PrivateRoute>
            <Layout>
              <OperationList />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/salesorderpendency" 
        element={
          <PrivateRoute>
            <Layout>
              <SalesOrderPendency />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/productionfloorplan" 
        element={
          <PrivateRoute>
            <Layout>
              <ProductionFloorPlan />
            </Layout>
          </PrivateRoute>
        } 
      />
    
        {/* Operation Management Routes */}
      {/* Operation Master Routes */}
      <Route 
        path="/operationmaster" 
        element={
          <PrivateRoute>
            <Layout>
              <OperationMaster />
            </Layout>
          </PrivateRoute>
        } 
      />
     
    
        {/* Operation Master Routes */}
      {/* Reports Routes */}
      <Route 
        path="/totalpendingreport" 
        element={
          <PrivateRoute>
            <Layout>
              <TotalPendingReport />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/productionreport" 
        element={
          <PrivateRoute>
            <Layout>
              <ProductionReport />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/salesorderpendencyreport" 
        element={
          <PrivateRoute>
            <Layout>
              <SalesOrderPendencyReport />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/salesorderreport" 
        element={
          <PrivateRoute>
            <Layout>
              <SalesOrderReport />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/clientwisependencyreport" 
        element={
          <PrivateRoute>
            <Layout>
              <ClientWisePendencyReport />
            </Layout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/viewclientwisereport/:reportId" 
        element={
          <PrivateRoute>
            <Layout>
              <ViewClientReport />
            </Layout>
          </PrivateRoute>
        } 
      />
     
    
        {/* Reports Routes */}
    
      
      <Route path="*" element={<Navigate to={token ? "/dashboard" : "/"} />} />
    </Routes>
  );
}

// export default AppContent;


function App() {
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;