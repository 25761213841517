import React, { useState } from 'react';
import './Sidebar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Bhama from '../../assets/bhama.png';
import client from '../../assets/sidebaricon/client.png';
import clientnotactive from '../../assets/sidebaricon/clientnotactive.png';
import dashboard from '../../assets/sidebaricon/dashboard.png';
import dashboardnotactive from '../../assets/sidebaricon/dashboardnotactive.png';
import ordermanagement from '../../assets/sidebaricon/ordermanagement.png';
import ordermanagementnotactive from '../../assets/sidebaricon/ordermanagementnotactive.png';
import operationmanagement from '../../assets/sidebaricon/operationmanagement.png';
import operationmanagementnotactive from '../../assets/sidebaricon/operationmanagementnotactive.png';
import pen from '../../assets/sidebaricon/PenNibStraight.png';
import pennotactive from '../../assets/sidebaricon/PenNibStraightnotactive.png';
import reports from '../../assets/sidebaricon/reports.png';
import reportsnotactive from '../../assets/sidebaricon/reportsnotactive.png';
import hrm from '../../assets/sidebaricon/hrm.png';
import hrmnotactive from '../../assets/sidebaricon/hrmnotactive.png';
import businesssetting from '../../assets/sidebaricon/businesssetting.png';
import businesssetingnotactive from '../../assets/sidebaricon/businesssetingnotactive.png';
import logout from '../../assets/sidebaricon/logout.png';
import logoutnotactive from '../../assets/sidebaricon/logoutnotactive.png';
const Sidebar = ({toggleSidebar,sidebarOpen}) => {
  // const [isOpen, setIsOpen] = useState(true);
  const [dropdowns, setDropdowns] = useState({
    clientmanagement: false,
    ordermanagement: false,
    operationmanagement: false,
    masters: false,
    reports: false,
    hrm: false,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItem, setactiveSubItem] = useState(null);

  const navigate = useNavigate()
  const Logout = ()=>{
  
    sessionStorage.removeItem('authtoken');
    window.location.reload()
    navigate('/')

       
      
     
      
      
  }

  const toggleDropdown = (dropdown) => {
    console.log(dropdowns)
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdown]: !prevDropdowns[dropdown],
    }));
    handleItemClick(dropdown);
  };
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const handleSubItemClick = (item) => {
    setactiveSubItem(item);
  };

  return (

    <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
      <header className="header">
        <button onClick={toggleSidebar} className="toggle-btn">
          {sidebarOpen ? <i className='bx bx-left-arrow-circle'></i> : <i className='bx bx-right-arrow-circle'></i>}
        </button>
      </header>
      <div className="content-sidebar1">
        {sidebarOpen ? (
          <>
            <div className="sidebar-logo-bhama">
              <img src={Bhama} alt='bhama' />
            </div>
            <div className="content-sidebar">
              <ul className='ul-sidebar'>
                <li className={`li-sidebar ${activeItem === 'dashboard' ? 'active' : ''}`} onClick={() => handleItemClick('dashboard')}>
                  <NavLink to="/dashboard">
                    {activeItem === 'dashboard' ?
                      <img src={dashboard} alt='' /> :
                      <img src={dashboardnotactive} alt='' />
                    }
                    Dashboard</NavLink>
                </li>
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'clientmanagement' ? 'active' : ''}`} onClick={() => toggleDropdown('clientmanagement')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'clientmanagement' ? <img src={client} alt='' />
                          : <img src={clientnotactive} alt='' />}
                        Client Management</div>
                    </span>
                  </li>
                  {dropdowns.clientmanagement && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'addclient' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'addclient' ? 'active' : ''} `} to="/addclient" onClick={() => handleSubItemClick('addclient')}>
                          Add Client
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'clientlist' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'clientlist' ? 'active' : ''} `} to="/clientlist" onClick={() => handleSubItemClick('clientlist')}>
                          Client List
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'ordermanagement' ? 'active' : ''}`} onClick={() => toggleDropdown('ordermanagement')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'ordermanagement' ? <img src={ordermanagement} alt='' />
                          : <img src={ordermanagementnotactive} alt='' />}
                        Order Management</div>
                    </span>
                  </li>
                  {dropdowns.ordermanagement && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'salesorder' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'salesorder' ? 'active' : ''} `} to="/salesorder" onClick={() => handleSubItemClick('salesorder')}>
                          Sales Order
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'orderdispatch' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'orderdispatch' ? 'active' : ''} `} to="/orderdispatch" onClick={() => handleSubItemClick('orderdispatch')}>
                          Order Dispatch
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'qualitycheck' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'qualitycheck' ? 'active' : ''} `} to="/qualitycheck" onClick={() => handleSubItemClick('qualitycheck')}>
                          Quality Check
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'jobcard' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'jobcard' ? 'active' : ''} `} to="/jobcard" onClick={() => handleSubItemClick('jobcard')}>
                          Job Card
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'operationmanagement' ? 'active' : ''}`} onClick={() => toggleDropdown('operationmanagement')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'operationmanagement' ? <img src={operationmanagement} alt='' />
                          : <img src={operationmanagementnotactive} alt='' />}
                        Operation Management</div>
                    </span>
                  </li>
                  {dropdowns.operationmanagement && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'operationlist' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'operationlist' ? 'active' : ''} `} to="/operationlist" onClick={() => handleSubItemClick('operationlist')}>
                          Operation List
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'salesorderpendency' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'salesorderpendency' ? 'active' : ''} `} to="/salesorderpendency" onClick={() => handleSubItemClick('salesorderpendency')}>
                          Sales Order Pendency
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'productionfloorplan' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'productionfloorplan' ? 'active' : ''} `} to="/productionfloorplan" onClick={() => handleSubItemClick('productionfloorplan')}>
                          Production Floor Plan
                        </NavLink>
                      </li>

                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'masters' ? 'active' : ''}`} onClick={() => toggleDropdown('masters')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'masters' ? <img src={pen} alt='' />
                          : <img src={pennotactive} alt='' />}
                        Masters</div>
                    </span>
                  </li>
                  {dropdowns.masters && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'operationmaster' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'operationmaster' ? 'active' : ''} `} to="/operationmaster" onClick={() => handleSubItemClick('operationmaster')}>
                          Operation Master
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'reports' ? 'active' : ''}`} onClick={() => toggleDropdown('reports')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'reports' ? <img src={reports} alt='' />
                          : <img src={reportsnotactive} alt='' />}
                        Reports</div>
                    </span>
                  </li>
                  {dropdowns.reports && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'totalpendingreport' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'totalpendingreport' ? 'active' : ''} `} to="/totalpendingreport" onClick={() => handleSubItemClick('totalpendingreport')}>
                          Total Pending Report
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'productionreport' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'productionreport' ? 'active' : ''} `} to="/productionreport" onClick={() => handleSubItemClick('productionreport')}>
                          Production Report
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'salesorderpendencyreport' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'salesorderpendencyreport' ? 'active' : ''} `} to="/salesorderpendencyreport" onClick={() => handleSubItemClick('salesorderpendencyreport')}>
                          Sales Order Pendency
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'salesorderreport' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'salesorderreport' ? 'active' : ''} `} to="/salesorderreport" onClick={() => handleSubItemClick('salesorderreport')}>
                          Sales Order Report
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'clientwisependencyreport' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'clientwisependencyreport' ? 'active' : ''} `} to="/clientwisependencyreport" onClick={() => handleSubItemClick('clientwisependencyreport')}>
                          Client Wise Pendency Report
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                {/* ========================================================= */}
                <div>
                  <li className={`dropdown-main li-sidebar ${activeItem === 'hrm' ? 'active' : ''}`} onClick={() => toggleDropdown('hrm')}>
                    <span  >
                      <div className='dropdown-btn'>
                        {activeItem === 'hrm' ? <img src={hrm} alt='' />
                          : <img src={hrmnotactive} alt='' />}
                        HRM</div>
                    </span>
                  </li>
                  {dropdowns.hrm && (
                    <ul className="dropdown-main-menu">
                      <li className={activeSubItem === 'employeemanagement' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'employeemanagement' ? 'active' : ''} `} to="/employeemanagement" onClick={() => handleSubItemClick('employeemanagement')}>
                          Employee Management
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'payrollmanagement' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'payrollmanagement' ? 'active' : ''} `} to="/payrollmanagement" onClick={() => handleSubItemClick('payrollmanagement')}>
                          Payroll Management
                        </NavLink>
                      </li>
                      <li className={activeSubItem === 'attendencemanagement' ? 'active' : ''}>
                        <NavLink className={`sidebar-dropdown-links ${activeSubItem === 'attendencemanagement' ? 'active' : ''} `} to="/attendencemanagement" onClick={() => handleSubItemClick('attendencemanagement')}>
                          Attendence Management
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
                {/* ========================================================= */}
                <li className={`li-sidebar ${activeItem === 'businesssetting' ? 'active' : ''}`} onClick={() => handleItemClick('businesssetting')}>
                  <NavLink to="/">
                    {activeItem === 'businesssetting' ?
                      <img src={businesssetting} alt='' /> :
                      <img src={businesssetingnotactive} alt='' />
                    }
                    Business Setting</NavLink>
                </li>
                {/* ============================================================= */}
                {/* ========================================================= */}
                <li className={`li-sidebar ${activeItem === 'logout' ? 'active' : ''}`} onClick={() => {handleItemClick('logout');
                Logout()
                }}>
                  <NavLink to="/">
                    {activeItem === 'logout' ?
                      <img src={logout} alt='' /> :
                      <img src={logoutnotactive} alt='' />
                    }
                    Logout</NavLink>
                </li>
                {/* ============================================================= */}
              </ul>

            </div>
          </>
        ) : (
          <div className="closed-icons">
          <div className='side-bar-img-min'>
          <img src={Bhama} alt='bhama' />
          </div>
            <ul className='ul-sidebar'>
              <li className={`li-sidebar-min ${activeItem === 'dashboard' ? 'active' : ''}`} onClick={() => handleItemClick('dashboard')}>
                <NavLink to="/">
                  {activeItem === 'dashboard' ?
                    <img src={dashboard} alt='' /> :
                    <img src={dashboardnotactive} alt='' />
                  }
                </NavLink>
              </li>
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                      {activeItem === 'clientmanagementmin' ? <img src={client} alt='' />
                        : <img src={clientnotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">
                      <NavLink to="/addclient">Add Client</NavLink>
                      <NavLink to="/clientlist">Client List</NavLink>
                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                      {activeItem === 'ordermanagementmin' ? <img src={ordermanagement} alt='' />
                        : <img src={ordermanagementnotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">
                      <NavLink to="/salesorder">Sales Order</NavLink>
                      <NavLink to="/orderdispatch">Order Dispatch</NavLink>
                      <NavLink to="/qualitycheck">Quality Check</NavLink>
                      <NavLink to="/jobcard">Job Card</NavLink>
                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                      {activeItem === 'operationmanagementmin' ? <img src={operationmanagement} alt='' />
                        : <img src={operationmanagementnotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">
                      <NavLink to="/operationlist">Operation List</NavLink>
                      <NavLink to="/salesorderpendency">Sales Order Pendency</NavLink>
                      <NavLink to="/productionfloorplan">Production Floor Plan</NavLink>
                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                      {activeItem === 'mastersmin' ? <img src={pen} alt='' />
                        : <img src={pennotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">
                      <NavLink to="/add-category">Operation Masters</NavLink>
                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                      {activeItem === 'reportsmin' ? <img src={reports} alt='' />
                        : <img src={reportsnotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">

                      <NavLink to="/totalpendingreport" >
                        Total Pending Report
                      </NavLink>


                      <NavLink to="/productionreport" >
                        Production Report
                      </NavLink>

                      <NavLink to="/salesorderpendency" >
                        Sales Order Pendency
                      </NavLink>

                      <NavLink to="/salesorderreport" >
                        Sales Order Report
                      </NavLink>

                      <NavLink to="/clientwisependencyreport" >
                        Client Wise Pendency Report
                      </NavLink>

                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              {/* ====================================================== */}
              <div>
                <li className="dropdown-min li-sidebar-min">
                  <span className="dropdown-btn-min">
                    <div>
                    {activeItem === 'hrmmin' ? <img src={hrm} alt='' />
                          : <img src={hrmnotactive} alt='' />}
                    </div>
                    <div className="dropdown-content-min">
                      <NavLink to="/totalpendingreport" >
                      Employee Management
                      </NavLink>
                      <NavLink to="/productionreport" >
                      Payroll Management
                      </NavLink>
                      <NavLink to="/salesorderpendency" >
                      Attendence Management
                      </NavLink>
                    </div>
                  </span>
                </li>
              </div>
              {/* ====================================================== */}
              <li className={`li-sidebar-min ${activeItem === 'businesssetting' ? 'active' : ''}`} onClick={() => handleItemClick('businesssetting')}>
                <NavLink to="/">
                {activeItem === 'businesssetting' ?
                      <img src={businesssetting} alt='' /> :
                      <img src={businesssetingnotactive} alt='' />
                    }
                </NavLink>
              </li>
               {/* ====================================================== */}
              {/* ====================================================== */}
              <li className={`li-sidebar-min ${activeItem === 'logout' ? 'active' : ''}`} onClick={() => {handleItemClick('logout');
              Logout();
              }}>
                <NavLink >
                {activeItem === 'logout' ?
                      <img src={logout} alt='' /> :
                      <img src={logoutnotactive} alt='' />
                    }
                </NavLink>
              </li>
               {/* ====================================================== */}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
