import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import '..css';
import edit from '../../assets/edit.png'
const TotalPendingRow = ({ data }) => {

 

    return (
        <tr className={`operation_list_table_body `}>
            <td data-label="S.No.">{data.id}.</td>
            <td data-label="Order Date">{data.orderDate}</td>
            <td data-label="Due Days">{data.dueDays}</td>
            <td data-label="Item Name">{data.itemName}</td>
            <td data-label="Order QTY">{data.orderQty}</td>
            <td data-label="Pending QTY">{data.pendingQty}</td>
        
        </tr>
    );
};

TotalPendingRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        dueDays: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        orderQty: PropTypes.string.isRequired,
        pendingQty: PropTypes.string.isRequired,
    }).isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    handleRemarkChange: PropTypes.func.isRequired,
};

export default function TotalPendingReport() {
    const initialTotalPendingReport = [
        { id: '1', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', },
        { id: '2', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', },
        { id: '3', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100',  },
    ];
    const [pendingReport, setpendingReport] = useState(initialTotalPendingReport);

    return (
        <>
            <div className='sales_order_pendency_section_header'>
                <h4>Total Pending Report</h4>
            
                    <div className='sales_order_pendency_date_section'>
                    <div className='sales_order_pendency_date'>
                    Start Date
                        <input type='date'/>
                    </div>
                    <div className='sales_order_pendency_date'>
                    End Date
                        <input type='date'/>
                    </div>
                    </div>
              
            </div>
            <section className='operation_section'>
                <div>
                    <table className='operation_list_table'>
                        <thead>
                            <tr className='operation_list_table_head'>
                                <th>S.No.</th>
                                <th>Order Date</th>
                                <th>Due Days</th>
                                <th>Item Name</th>
                                <th>Order QTY</th>
                                <th>Pending QTY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingReport.map((data) => (
                                <TotalPendingRow 
                                    data={data} 
                                    key={data.id} 
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </section>
            <section className='sales_order_pendency_total_section'>
                    <div className='sales_order_pendency_total_dispatch'>
                       Total Pending: <span>800 Qty</span>
               </div>
                   
                </section>
        </>
    );
}
