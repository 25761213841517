import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './salesorderpendency.css';
import edit from '../../assets/edit.png'
const SalesOrderPendencyRow = ({ data, handleStatusChange, handleRemarkChange,IsEdit,handleEdit }) => {
   
    const getStatusClass = (status) => {
        switch(status.toLowerCase()) {
            case 'open':
                return 'sales_order_pendency_status_open';
            case 'close':
                return 'sales_order_pendency_status_close';
            default:
                return '';
        }
    };
 

    return (
        <tr className={`operation_list_table_body `}>
            <td data-label="S.No.">{data.id}.</td>
            <td data-label="Order Date">{data.orderDate}</td>
            <td data-label="Due Days">{data.dueDays}</td>
            <td data-label="Item Name">{data.itemName}</td>
            <td data-label="Order QTY">{data.orderQty}</td>
            <td data-label="Pending QTY">{data.pendingQty}</td>
            <td data-label="Status" className='operation_list_status'>
            {IsEdit === true ?
                <select
                    className='sales_order_pendency_dropdown_status'
                    value={data.status.toLowerCase()}
                    onChange={(e) => handleStatusChange(data.id, e.target.value)}
                >
                    <option value="open">open</option>
                    <option value="close">close</option>
                </select> : <span className={`${getStatusClass(data.status)}`}>{data.status}</span>}
            </td>
            <td data-label="Remark">
            {IsEdit === true ?(
                <input
                    type="text"
                    value={data.remark}
                    className="remark_input"
                    onChange={(e) => handleRemarkChange(data.id, e.target.value)}
                    autoFocus
                /> )
                : (<span>{data.remark}</span>)}
            </td>
            <td>
                <div
                    className='sales_order_pendency_edit_button'
                >
                    <img  onClick={()=>{handleEdit(data.id)}} src={edit} alt='edit'/>
                </div>
            </td>
        </tr>
    );
};

SalesOrderPendencyRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        dueDays: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        orderQty: PropTypes.string.isRequired,
        pendingQty: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        remark: PropTypes.string.isRequired,
    }).isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    handleRemarkChange: PropTypes.func.isRequired,
};

export default function SalesOrderPendency() {
    const initialSalesOrderPendency = [
        { id: '1', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'open', remark: 'Xyz' },
        { id: '2', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'close', remark: 'ABC' },
        { id: '3', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'open', remark: 'DEF' },
    ];
    const [pendency, setPendency] = useState(initialSalesOrderPendency);
    const [editingId,setEditingId] = useState(null);
    const handleStatusChange = (id, newStatus) => {
        setPendency(pendency.map(order => 
            order.id === id ? { ...order, status: newStatus } : order
        ));
    };

    const handleRemarkChange = (id, newRemark) => {
        setPendency(pendency.map(order => 
            order.id === id ? { ...order, remark: newRemark } : order
        ));
    };

    const handleEdit = (id) => {
        setEditingId(editingId === id ? null : id);
    };
     
    return (
        <>
            <div className='sales_order_pendency_section_header'>
                <h4>Sales Order Pendency</h4>
                {editingId ? (<div className='sales_order_pendency_save_btn'>
                    <button>Save</button>
                </div>) : (
                    <div className='sales_order_pendency_date_section'>
                    <div className='sales_order_pendency_date'>
                    Start Date
                        <input type='date'/>
                    </div>
                    <div className='sales_order_pendency_date'>
                    End Date
                        <input type='date'/>
                    </div>
                    </div>
                )}
            </div>
            <section className='operation_section'>
                <div>
                    <table className='operation_list_table'>
                        <thead>
                            <tr className='operation_list_table_head'>
                                <th>S.No.</th>
                                <th>Order Date</th>
                                <th>Due Days</th>
                                <th>Item Name</th>
                                <th>Order QTY</th>
                                <th>Pending QTY</th>
                                <th>Status</th>
                                <th>Remark</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendency.map((data) => (
                                <SalesOrderPendencyRow 
                                    data={data} 
                                    key={data.id} 
                                    handleStatusChange={handleStatusChange}
                                    handleRemarkChange={handleRemarkChange}
                                    handleEdit ={handleEdit}
                                    IsEdit={editingId === data.id}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </section>
            <section className='sales_order_pendency_total_section'>
                    <div className='sales_order_pendency_total_dispatch'>
                       Total Dispatch: <span>800 Qty</span>
               </div>
                    <div className='sales_order_pendency_total_dispatch'>
                       Total Dispatch: <span>800 Qty</span>
               </div>
                </section>
        </>
    );
}
