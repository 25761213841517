
import React from 'react';
import './client_list.css';
import edit from '../../assets/edit.png';
import Delete from '../../assets/delete.png';
import view from '../../assets/view.png';
import { useNavigate } from 'react-router-dom';

const clients = [
  { id: '1', name: 'myur', state: 'khan', city: 'indore', mobile: '+919999999999' },
  { id: '2', name: 'myur', state: 'khan', city: 'indore', mobile: '+919999999999' },
  { id: '3', name: 'myur', state: 'khan', city: 'indore', mobile: '+919999999999' }
];

const ClientRow = ({ client }) => {
  const navigate = useNavigate();

  const handleEdit = (clientId) => {
    console.log("Navigating to edit client:", clientId);
    navigate(`/editclient/${clientId}`);
  };
  const handleView = (clientId) => {
    console.log("Navigating to view client:", clientId);
    navigate(`/clientview/${clientId}`);
  };

  return (
    <tr key={client.id}>
      <td data-label="S.No">{client.id}.</td>
      <td data-label="Client Name">{client.name}</td>
      <td data-label="State">{client.state}</td>
      <td data-label="City">{client.city}</td>
      <td data-label="Mobile No.">{client.mobile}</td>
      <td data-label="Action">
        <div className='client_list_action'>
          <img src={edit} onClick={() => handleEdit(client.id)} alt='Edit' />
          <img src={Delete} alt='Delete' />
          <img src={view} onClick={() => handleView(client.id)} alt='View' />
        </div>
      </td>
    </tr>
  );
};

const ListClient = () => {
  return (
    <section className='client_list_section'>
      <div className='client_list_header'>
        <h3>List clients</h3>
      </div>

      <div>
        <table className='client_list_table'>
          <thead>
            <tr className='client_list_table_head'>
              <th>S.No</th>
              <th>Client Name</th>
              <th>State</th>
              <th>City</th>
              <th>Mobile No.</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className='client_list_table_body'>
            {clients.map(client => (
              <ClientRow key={client.id} client={client} />
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ListClient;
