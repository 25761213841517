import React, { useState } from 'react'
import PropTypes from 'prop-types';


const SalesOrderPendencyRow = ({ data }) => {

 
     return (
         <tr className={`operation_list_table_body `}>
             <td data-label="S.No.">{data.id}.</td>
             <td data-label="Order Date">{data.orderDate}</td>
             <td data-label="Due Days">{data.dueDays}</td>
             <td data-label="Item Name">{data.itemName}</td>
             <td data-label="Order QTY">{data.orderQty}</td>
             <td data-label="Pending QTY">{data.pendingQty}</td>
             <td data-label="Status">
                  {data.status}
             </td>
         </tr>
     );
 };
 
 SalesOrderPendencyRow.propTypes = {
     data: PropTypes.shape({
         id: PropTypes.string.isRequired,
         orderDate: PropTypes.string.isRequired,
         dueDays: PropTypes.string.isRequired,
         itemName: PropTypes.string.isRequired,
         orderQty: PropTypes.string.isRequired,
         pendingQty: PropTypes.string.isRequired,
         status: PropTypes.string.isRequired,
     }).isRequired,
     handleStatusChange: PropTypes.func.isRequired,
     handleRemarkChange: PropTypes.func.isRequired,
 };
export default function ViewClientReport() {
    const initialSalesOrderPendency = [
        { id: '1', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'Ongoing',  },
        { id: '2', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'Pending', },
        { id: '3', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', pendingQty: '100', status: 'Ongoing',  },
    ];
    const [pendency, setPendency] = useState(initialSalesOrderPendency);
   
   
     
    return (
        <>
            <div className='sales_order_pendency_section_header'>
                <h4>Client Wise Pendency Report</h4>                
            </div>
            <section className='operation_section'>
                <div>
                    <table className='operation_list_table'>
                        <thead>
                            <tr className='operation_list_table_head'>
                                <th>S.No.</th>
                                <th>Order Date</th>
                                <th>Due Days</th>
                                <th>Item Name</th>
                                <th>Order QTY</th>
                                <th>Pending QTY</th>

                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendency.map((data) => (
                                <SalesOrderPendencyRow 
                                    data={data} 
                                    key={data.id} 
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </section>


    </>
  )
}
