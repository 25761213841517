import React, { useState } from 'react'
import Header from './header/Header'
import Sidebar from './sidebar/SideBar'
import './layout.css';
export default function Layout({children}) {
        const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className="App1">
    <Sidebar  toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen}/>
      <div className="content">
      <Header />
      {children}
    </div>
  </div>
  )
}


