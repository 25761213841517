import React from 'react'
import login from '../../assets/loginpageimage.png';
import './login.css';
export default function Login() {

  const Login = ()=>{
        const token = "Logged in";
        sessionStorage.setItem('authtoken',token)
  }
  return (
    <>
        <div className='main-container-login'>
        <div className='main-login-image'>
            <img src={login} alt="login" />
        </div>

        <section>
        <div className="login-form-section">
      <h1 className="login-form-heading">Welcome Back!</h1>
      <form className='login-form'>
        <div className="form-group">
          <label className="form-label-login">User Name</label>
          <input type="text" className="login-form-custom-input" placeholder="Enter your user name" />
        </div>
        <div className="form-group">
          <label className="form-label-login">Password</label>
          <input type="password" className="login-form-custom-input" placeholder="Enter your password" />
        </div>
        <div className="form-group">
          <input type="checkbox" id="remember-me" />
          <label htmlFor="remember-me" className="form-remember-me">Remember me</label>
        </div>
        <div>
          <button onClick={Login} type="submit" className="login-form-button">Login</button>
        </div>
      </form>
    </div>
        </section>

        </div>
    </>
  )
}
