import React, { useState } from 'react'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './add_client.css';
export default function EditClient() {
    const [phone, setphone] = useState();
    return (
        <>
                <div className='client_details_header_edit_view'>
                    <h3>Edit Client</h3>
                    <div>
                        <button className='client_details_save_btn'>Save</button>
                    </div>
                </div>
                <div className='client_details_sub_header'>
               <h3>Client Details</h3>
               </div>
            <section className='client_details'>

                <div>
                    <form >
                    <div className='client_details_form'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="name">Client Name</label>
                                    <input type="text" className="form-control" id="name" value='Mayur' aria-describedby="emailHelp" placeholder="Enter Name" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="address">Client Address</label>
                                    <input type="text" className="form-control" id="address" value={"indore gate 5"} aria-describedby="emailHelp" placeholder="Enter Address" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="company">Company Name</label>
                                    <input type="text" className="form-control" id="company" value={"Bellway"} aria-describedby="emailHelp" placeholder="Enter Company Name" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="city">City</label>
                                    <select className='form-control'>
                                        <option>Select City</option>
                                        <option>Indore</option>
                                        <option>City 2</option>
                                        <option>City 3</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="pin">Pin Number</label>
                                    <input type="text" className="form-control" id="pin" value={"897636"} aria-describedby="emailHelp" placeholder="Enter Pin Number" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="name">Company Name</label>
                                    <PhoneInput
                                        defaultCountry="in"
                                        value={phone}
                                        onChange={(phone) => setphone(phone)}
                                        className="custom-phone-input"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="email">Email ID</label>
                                    <input type='email' id='email' value={"Email@gmail.com"} className="form-control" aria-describedby="emailHelp" placeholder='Enter Email'/>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="state">State</label>
                                    <input type="text" className="form-control" value={"MP"} id="state"  placeholder="Enter State" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor="gst">GST Number</label>
                                    <input type="text" className="form-control" value={"JSGqiwudbjg32"} id="gst"  placeholder="Enter GST" />
                                </div>
                            </div>
                    </div>
                        </div>
                    </form>
                </div>
            </section>

        </>
    )
}
