import React, { useState } from 'react'
import view from '../../assets/view.png';
import './operationmaster.css'
const MasterRow = ({data})=>{
  
    return(
        <tr key={data.id}>
        <td>{data.id}.</td>
        <td>{data.process}</td>
   
        <td>
          <div className='client_list_action'>
            <img src={view}  alt='View' />
          </div>
        </td>
      </tr>
    )
}
export default function OperationMaster() {

    const [masterProcess, setMasterProcess] = useState([
        { id: '1', process: 'Reel to sheet cutting' },
        { id: '2', process: 'Silver Lamination' },
        { id: '3', process: 'Cutting' },
        { id: '4', process: 'Printing' },
        { id: '5', process: 'Coating' },
        { id: '6', process: 'UV Full Gloss' },
        { id: '7', process: 'Drip Off' },
      ]);

      const [newProcess, setNewProcess] = useState('');
     const handleaddProcess = ()=>{
        if(newProcess.trim()){
            const newId = (masterProcess.length + 1).toString();
            setMasterProcess([...masterProcess, { id: newId, process: newProcess }]);
            setNewProcess('')
        }
     }
  return (
    <> <section className='client_list_section'>
    <div className='operation_master_header'>
       <div className='form-group col-3'>
       <label htmlFor='process' className='operation_master_label'>Creater Process</label>
        <input type='text' value={newProcess} onChange={(e)=>setNewProcess(e.target.value)} id='process' className='form-control' placeholder='Enter process name'/>
       </div>
       <div>
        <button onClick={handleaddProcess} type='submit' className='operation_master_btn'>Save</button>
       </div>
    </div>

    <div className='operation_master_table_div'>
      <table className='client_list_table'>
        <thead>
          <tr className='client_list_table_head'>
            <th>S.No</th>
            <th>Process</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className='client_list_table_body'>
          {masterProcess.map(data => (
            <MasterRow key={data.id} data={data} />
          ))}
        </tbody>
      </table>
    </div>
  </section></>
  )
}
