import React from 'react'
import Select from 'react-select';
export default function EditSalesOrder() {

    

      const options = [
          { value: 'mayur', label: 'Mayur' },
          { value: 'sai', label: 'Sai' },
          { value: 'lavisha', label: 'Lavisha' }
      ];
  return (
    <>
    <section>
                <div className='new_sales_order_header'>
                    <h3>
                        New Sales Order
                    </h3>
                </div>
                <div className='new_sales_order_sub_header'>
                    <div className='form-group col-md-3'>
                        <label htmlFor='client_name'>Select Client</label>
                        <Select
                            options={options}
                            placeholder="Select Client"
                            isSearchable={true}
                        />
                    </div>
                </div>
                <div className='new_sales_order_form'>
                    <form className='new_sales_order_form_main'> 
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_date'>Order Date</label>
                                    <input type='date' className='form-control' id='order_date' placeholder='Enter Order Date' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_id'>Order ID</label>
                                    <input type='text' className='form-control' id='order_id'  value={`#646572`} placeholder='Enter Order Id' readOnly />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='item_name'>Item Name</label>
                                    <input type='text' className='form-control' id='item_name' placeholder='Enter Item Name' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='hsn_code'>HSN code</label>
                                    <input type='text' className='form-control' id='hsn_code' placeholder='41251' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='item_name'>Quantity</label>
                                    <input type='number' min='0' className='form-control number_input' id='item_name' placeholder='Enter Quantity' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='order_date'>Order Date</label>
                                    <input type='date' className='form-control' id='order_date' placeholder='Enter Order Date' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='rate'>Rate</label>
                                    <input type='text' className='form-control' id='rate' placeholder='₹14000' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='total_amount'>Total Amount</label>
                                    <input type='number' min='0' className='form-control number_input' id='total_amount' placeholder='Enter Total Amount' />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label htmlFor='sales_person'>Sales Person</label>
                                    <input type='text' className='form-control' id='sales_person' placeholder='Enter Sales person' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <h6>GST Included 18%</h6>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    SGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    CGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='gst_card_1'>
                                    IGST
                                    <section className='gst_rate_rs_section'>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rate </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                        <div className='gst_sub_card_1'>
                                            <div className='gst_rate'>Rs </div>
                                            <input
                                                type='text'
                                                className='gst_percent_input'
                                                value={'9%'}

                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </section>
        
    </>
  )
}
