import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './operationlist.css';
import view from '../../assets/view.png';
import './productionfloorplan.css';
const ProductionRow = ({ data, handlePriorityChange }) => {
    const handleChange = (e) => {
        const newPriority = parseInt(e.target.value, 10);
        if (!isNaN(newPriority)) {
            handlePriorityChange(data.id, newPriority);
        }
    };
           
    return (
        <tr className='operation_list_table_body'>
            <td  data-label="S.No.">{data.id}.</td>
            <td data-label="Order Date">{data.orderDate}</td>
            <td data-label="Due Days">{data.dueDays}</td>
            <td data-label="Item Name">{data.itemName}</td>
            <td data-label="Order QTY">{data.scheduleQty}</td>
            <td data-label="Order QTY">{data.product}</td>
            <td data-label="Order QTY">{data.orderQty}</td>
            <td data-label="Pending QTY">{data.pendingQty}</td>
            <td data-label="Priority">
                {data.priority}
            </td>
            <td data-label="Status" className='operation_list_status'><span className={`${data.status.toLowerCase().replace(" ", "-")}`}>{data.status}</span></td>
            <td data-label="Action" className='production_floor_action'>
                <img src={view} alt='view'/>
            </td>
        </tr>
    );
};

ProductionRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        dueDays: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        orderQty: PropTypes.string.isRequired,
        scheduleQty: PropTypes.string.isRequired,
        product: PropTypes.number.isRequired,
        pendingQty: PropTypes.string.isRequired,
        priority: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    handlePriorityChange: PropTypes.func.isRequired,
};

const ProductionSection = ({ title, data, handlePriorityChange }) => {
    return (
        <>

           <div className='operation_section_header'>
            <h4>{title}</h4>
           </div>
        <section className='operation_section'>
            <div>
                <table className='operation_list_table'>
                    <thead>
                        <tr className='operation_list_table_head'>
                            <th>S.No.</th>
                            <th>Order Date</th>
                            <th>Due Days</th>
                            <th>Item Name</th>
                            <th>Order QTY</th>
                            <th>Schedule QTY</th>
                            <th>Product</th>
                            <th>Pending QTY</th>
                            <th>Priority</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((rowData) => (
                            <ProductionRow key={rowData.id} data={rowData} handlePriorityChange={handlePriorityChange} />
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
        </>
    );
};

ProductionSection.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        dueDays: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        orderQty: PropTypes.string.isRequired,
        scheduleQty: PropTypes.string.isRequired,
        product: PropTypes.number.isRequired,
        pendingQty: PropTypes.string.isRequired,
        priority: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    })).isRequired,
    handlePriorityChange: PropTypes.func.isRequired,
};

export default function ProductionFloorPlan() {
    const initialReeltosheetcutting = [
        { id: '1', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100, pendingQty: '100', priority: 2, status: 'Pending' },
        { id: '2', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 4, status: 'On going' },
        { id: '3', orderDate: '12/12/2024', dueDays: '8 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 0, status: 'Pending' },
        
    ];

    const initialSilverlamination = [
        { id: '1', orderDate: '12/12/2024', dueDays: '10 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 2, status: 'Pending' },
        { id: '2', orderDate: '12/12/2024', dueDays: '12 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 4, status: 'On going' },
        { id: '3', orderDate: '12/12/2024', dueDays: '13 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 0, status: 'Pending' },
       
    ];
    const initialCutting = [
        { id: '1', orderDate: '12/12/2024', dueDays: '10 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 2, status: 'Pending' },
        { id: '2', orderDate: '12/12/2024', dueDays: '12 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 4, status: 'On going' },
        { id: '3', orderDate: '12/12/2024', dueDays: '13 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 0, status: 'Pending' },
      
    ];
    const initialPrinting = [
        { id: '1', orderDate: '18/12/2024', dueDays: '10 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 2, status: 'Pending' },
        { id: '2', orderDate: '12/12/2024', dueDays: '12 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 4, status: 'On going' },
        { id: '3', orderDate: '89/12/2024', dueDays: '13 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 0, status: 'Pending' },
    ]

    const initialCoating = [
        { id: '1', orderDate: '18/12/2024', dueDays: '10 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 2, status: 'Pending' },
        { id: '2', orderDate: '12/12/2024', dueDays: '12 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 4, status: 'On going' },
        { id: '3', orderDate: '89/12/2024', dueDays: '13 Days', itemName: 'Lorem Ipsum', orderQty: '100', scheduleQty: '100',product:100,  pendingQty: '100', priority: 0, status: 'Pending' },
      
    ];

    const [reel, setReel] = useState(initialReeltosheetcutting);
    const [silver, setSilver] = useState(initialSilverlamination);
    const [cutting, setCutting] = useState(initialCutting);
    const [printing, setPrinting] = useState(initialPrinting);
    const [coating, setCoating] = useState(initialCoating);

    const handlePriorityReelChange = (id, newPriority) => {
        setReel((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, priority: newPriority } : item
            )
        );
    };

    const handlePrioritySilverChange = (id, newPriority) => {
        setSilver((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, priority: newPriority } : item
            )
        );
    };
    const handlePriorityCuttingChange = (id, newPriority) => {
        setCutting((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, priority: newPriority } : item
            )
        );
    };
    const handlePriorityPrintingChange = (id, newPriority) => {
        setPrinting((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, priority: newPriority } : item
            )
        );
    };
    const handlePriorityCoatingChange = (id, newPriority) => {
        setCoating((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, priority: newPriority } : item
            )
        );
    };

    return (
        <>
        <section style={{height:'600px',overflowY:'auto'}}>
            <ProductionSection title="Reel to sheet cutting" data={reel} handlePriorityChange={handlePriorityReelChange} />
            <ProductionSection title="Silver Lamination" data={silver} handlePriorityChange={handlePrioritySilverChange} />
            <ProductionSection title="Cutting" data={cutting} handlePriorityChange={handlePriorityCuttingChange} />
            <ProductionSection title="Printing" data={printing} handlePriorityChange={handlePriorityPrintingChange} />
            <ProductionSection title="Coating" data={coating} handlePriorityChange={handlePriorityCoatingChange} />
            <ProductionSection title="UV Full Gloss" data={coating} handlePriorityChange={handlePriorityCoatingChange} />
            <ProductionSection title="Drip Off" data={coating} handlePriorityChange={handlePriorityCoatingChange} />
            </section>
            
        </>
    );
}
