
import React from 'react';
import './sales_order_list.css';
import edit from '../../assets/edit.png';
import Delete from '../../assets/delete.png';
import view from '../../assets/view.png';
import { useNavigate } from 'react-router-dom';
import add from '../../assets/add.png';
const orders = [
  { id: '1',date:'07/12/24', name: 'myur', product: 'khan', delivery: '09/12/24',  },
  { id: '2',date:'07/12/24', name: 'myur', product: 'khan', delivery: '09/12/24',  },
  { id: '3',date:'07/12/24', name: 'myur', product: 'khan', delivery: '09/12/24',  }
];

const SalesOrderRow = ({ order }) => {
  const navigate = useNavigate();
   
    const handleEdit=(orderid)=>{
        navigate(`/editsalesorder/${orderid}`)
    }
  const handleView = (orderid) => {
    navigate(`/viewsalesorder/${orderid}`);
  };

  return (
    <tr key={order.id}>
      <td>{order.id}.</td>
      <td>{order.date}</td>
      <td>{order.name}</td>
      <td>{order.product}</td>
      <td>{order.delivery}</td>
      <td>
        <div className='client_list_action'>
          <img src={edit} onClick={() => handleEdit(order.id)} alt='Edit' />
          <img src={Delete} alt='Delete' />
          <img src={view} onClick={() => handleView(order.id)} alt='View' />
        </div>
      </td>
    </tr>
  );
};

const SalesOrderList = () => {
    const navigate = useNavigate();
    const handleNewSalesOrder =()=>{
        navigate('/newsalesorder');
    }
  return (
    <section className='client_list_section'>
          <div className='sales_order_list_create_btn'>
     <img src={add} alt='icon'/>
       <button onClick={handleNewSalesOrder}>Create New Sales Order</button>
     </div>

      <div>
        <table className='client_list_table'>
          <thead>
            <tr className='client_list_table_head'>
              <th>S.No</th>
              <th>Date</th>
              <th>Client Name</th>
              <th>Product Name</th>
              <th>Delivery Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className='client_list_table_body'>
            {orders.map(order => (
              <SalesOrderRow key={order.id} order={order} />
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SalesOrderList;
