import React, { useState } from 'react'
import './productionreport.css'
export default function ProductionReport() {

    const [productionreport, setProductionReport] = useState([
        { title: 'Reel To Sheet Cutting', qty: 1000 },
        { title: 'Silver Lamination', qty: 100 },
        { title: 'Cutting', qty: 100 },
        { title: 'Printing', qty: 100 },
        { title: 'Coating', qty: 100 },
        { title: 'UV Full Gloss', qty: 100 },
        { title: 'Drip Off', qty: 100 },
    ])
    return (
        <>
            <div className='sales_order_pendency_section_header'>
                <h4>Total Pending Report</h4>

                <div className='sales_order_pendency_date_section'>
                    <div className='sales_order_pendency_date'>
                        Start Date
                        <input type='date' />
                    </div>
                    <div className='sales_order_pendency_date'>
                        End Date
                        <input type='date' />
                    </div>
                </div>

            </div>

            <section className='production_pending_card_section'>
              {productionreport.map((production)=>(

                <div className='production_pending_card'>
                    <div className='production_pending_sub_card_1'>
                        {production.title}
                    </div>
                    <div className='production_pending_sub_card_2'>
                        {production.qty} Qty
                    </div>
                </div>
              ))}
              

            </section>
        </>
    )
}
