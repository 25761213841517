import React from 'react'
import { useAuth } from '../../authentication/AuthContext';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({ children }) {
  const { token } = useAuth();
  console.log(token);
  return token ? children : <Navigate to="/" />;
}

